export default class DelayLoad {
  constructor() {
    this.action = ['keydown', 'mousedown', 'mousemove', 'touchmove', 'touchstart', 'touchend', 'wheel']
    this.callDelayB = false
  }

  delayCss() {
    $('.lazy-event').removeClass('lazy-event')
    $('[data-lazycss="true"]').each((i, e) => {
      $(e).replaceWith(`<link rel="stylesheet" id="${$(e).attr('id')}"  href="${$(e).data('href')}" media="screen">`)
    })
    if (typeof window.lazyLoadBg !== 'undefined') {
      window.lazyLoadBg.init()
    }
  }

  delayJs() {
    const $delayBeforeJs = $('[data-lazyjs="true"]')
    let loadedBeforeJs = 0
    const delayBeforeJsLength = $delayBeforeJs.length

    $delayBeforeJs.each((i, e) => {
      const scriptUrl = $(e).data('src')
      $(e).removeAttr('data-lazyjs data-src')
      $(e).attr('src', scriptUrl).on('load', () => {
        loadedBeforeJs++
        if (loadedBeforeJs === delayBeforeJsLength) {
          this.waitForRecaptcha()
        }
      })
    })
  }

  loadDelayAfterScripts($delayAfter) {
    $delayAfter.each((i, e) => {
      const scriptUrlAfter = $(e).data('src')
      $(e).removeAttr('data-lazyjs-after data-src')
      $(e).attr('src', scriptUrlAfter)
    })
  }

  waitForRecaptcha() {
    if (typeof grecaptcha !== 'undefined') {
      const $delayRecaptchaAfter = $('[data-lazyjs-after="true"]')
      const delayAfterJsLength = $delayRecaptchaAfter.length
      if (delayAfterJsLength > 0) {
        this.loadDelayAfterScripts($delayRecaptchaAfter)
      }
    } else {
      setTimeout(() => {
        this.waitForRecaptcha()
      }, 1000)
    }
  }

  callDelay() {
    if (this.callDelayB) return
    this.callDelayB = true
    this.delayCss()
    this.delayJs()
  }

  addEventListeners() {
    this.action.forEach((ac) => {
      document.addEventListener(ac, () => {
        this.callDelay()
      })
    })
  }

  init() {
    this.addEventListeners()
    if ($(window).scrollTop() > 0) {
      this.callDelay()
    }
  }
}

// Usage
const delayLoad = new DelayLoad()
delayLoad.init()
